<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: 'productions-items-create' })"
        >
          <i class="bi bi-file-earmark-plus fs-4"></i>
          Ajouter d'autres articles
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <label for=""> Date</label>
        <input type="date" v-model="production.date" class="form-control" />
      </div>
      <div class="col">
        <label for=""> Remarque </label>
        <textarea
          rows="1"
          v-model="production.remark"
          class="form-control"
        ></textarea>
      </div>
    </div>
    <br />

    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(production)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      production: {
        date: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
    };
  },
  computed: {},
  methods: {
    async save(data) {
      await this.$store.dispatch("production/store", data);
    },
  },
  beforeMount() {},
};
</script>
